import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { LEARN_MORE_ABOUT_STAKING_IN_COMMUNITY_POOLS_LINK } from '../../../constants/externalLinks';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { ModalContainer } from '../ModalContainer';
import { Text } from '../Text';

import { StakeModalProps } from './StakeModal';

export interface StakeTermsModalProps extends StakeModalProps {}

const SCROLLING_TOLERANCE = 3;

export function StakeTermsModal({ creator }: StakeTermsModalProps) {
  const { showModal, hideModal } = useGlobalModal();
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleContinueClick = () => {
    hideModal('StakeTermsModal');
    showModal({ modalType: 'StakeModal', modalProps: { creator } });
  };

  const handleScroll = useCallback(() => {
    const element = contentRef.current;
    if (element) {
      const { scrollTop, scrollHeight, clientHeight } = element;

      const scrollDiff = scrollTop + clientHeight - scrollHeight;
      setIsScrolledToBottom(Math.abs(scrollDiff) < SCROLLING_TOLERANCE);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const element = contentRef.current;
      if (element) {
        element.addEventListener('scroll', handleScroll);
      }
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      const element = contentRef.current;
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <ModalContainer
      title="Staking Feature Terms Of Use"
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 960px)' } }}
    >
      <>
        <Typography fontSize={20} fontWeight={500} mt={10}>
          Last Updated: Aug 1, 2024
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          maxHeight="350px"
          mt={5}
          mb={10}
          gap={5}
          sx={{ overflowY: 'auto' }}
          ref={contentRef}
          onScroll={handleScroll}
        >
          <Typography>Restricted Jurisdictions and Eligibility</Typography>
          <Text>
            The staking feature offered by Alpha Global Labs Ltd. (hereinafter
            referred to as "Altcoinist") is subject to certain restrictions
            based on jurisdiction. Users from certain regions are not permitted
            to access or utilize this feature due to regulatory constraints. By
            using Altcoinist and its services, you agree to comply with these
            restrictions and affirm that you do not reside in or access
            Altcoinist from any of the restricted jurisdictions listed below.
          </Text>
          <Typography>1. Restricted Jurisdictions</Typography>
          <Text>
            The staking feature is not available to individuals or entities
            located in, incorporated, or established in the United States of
            America (USA). This includes all states, territories, and
            possessions of the USA. Altcoinist reserves the right to amend the
            list of restricted jurisdictions at any time without prior notice.
          </Text>
          <Text fontStyle="italic">
            Neither you nor, in the event you are accepting these Terms on
            behalf of a corporation, any of your Subsidiaries, directors,
            officers, shareholders, beneficial owners, employees, agents, or any
            other person acting for you or any of your Subsidiaries is an
            individual or entity that is, or is owned or controlled by, an
            individual or entity that:
          </Text>

          <Text fontStyle="italic">
            a. is currently subject to any sanction imposed by His Majesty’s
            Treasury of the United Kingdom or the UK’s Office of Financial
            Sanctions Implementation (OFSI) (list available here:
            https://search-uk-sanctions-list.service.gov.uk/), or is subject to
            any similar sanctions or measures imposed by His Majesty’s Treasury
            of the United Kingdom, as such lists may be amended from time to
            time (collectively, the “Sanctions”);
          </Text>
          <Text fontStyle="italic">
            b. is located, situated, established, organized, or residing in a
            country or territory that is the subject of such Sanctions,
            including, but not limited to, Russia, Belarus, Iran, North Korea,
            Syria, Venezuela, Myanmar, and/or Cuba; or
          </Text>
          <Text fontStyle="italic">
            c. has engaged, or is now engaged, in any affairs or transactions
            with any person, government, project, venture, or other entity
            located in, or subject to the laws of, any country or territory,
            that at the time of the dealing or transaction is or was the subject
            of any Sanctions;
          </Text>
          <Typography>2. Prohibited Use by US Citizens</Typography>
          <Text>
            Eligibility: The staking feature is expressly prohibited for use by
            citizens, residents, or those physically present in the USA. If you
            are a US citizen, resident, or otherwise subject to US law, you must
            not attempt to use or access the staking feature on Altcoinist.
            Verification: Altcoinist implements verification processes to ensure
            compliance with this restriction. By attempting to access the
            staking feature, you consent to such verification procedures, which
            may include but are not limited to IP address checks and other
            methods deemed appropriate by Altcoinist.
          </Text>
          <Typography>3. Legal Compliance</Typography>
          <Text>
            Acknowledgment: You acknowledge that it is your responsibility to
            ensure that your use of Altcoinist complies with all applicable laws
            and regulations of your jurisdiction. By accessing and using the
            staking feature, you represent and warrant that such access and use
            do not violate any laws or regulations applicable to you. Liability:
            Altcoinist shall not be held liable for any legal consequences
            arising from your use of the staking feature in violation of the
            applicable restrictions. You agree to indemnify and hold harmless
            Altcoinist from any claims, losses, damages, or expenses incurred as
            a result of your breach of these terms.
          </Text>
          <Typography>4. Dispute</Typography>
          <Text>
            Dispute Resolution: Any disputes arising from the enforcement of
            this section shall be resolved in accordance with the dispute
            resolution provisions outlined in Altcoinist's general Terms and
            Conditions.
          </Text>
          <Typography>5. Amendments</Typography>
          <Text>
            Altcoinist reserves the right to amend, modify, or update this
            section at any time. It is your responsibility to review these terms
            periodically for any changes. Continued use of the staking feature
            following the posting of changes will constitute your acceptance of
            such changes.
          </Text>
          <Text>
            By clicking “Accept Terms & Continue” and using Altcoinist to access
            the staking feature, you affirm that you have read, understood, and
            agree to be bound by the terms and conditions set forth in this
            section.
          </Text>
        </Box>

        <Box display="flex" gap={3}>
          <Button
            onClick={handleContinueClick}
            disabled={!isScrolledToBottom} // Disable until scrolled to bottom
          >
            Accept Terms & Continue
          </Button>

          <Button
            variant="outlined"
            target={'_blank'}
            rel="noopener noreferrer"
            href={LEARN_MORE_ABOUT_STAKING_IN_COMMUNITY_POOLS_LINK}
          >
            Learn more
          </Button>
        </Box>
      </>
    </ModalContainer>
  );
}
