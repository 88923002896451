import { Box, Button, Typography } from '@mui/material';

import { SuccessSmallIcon, WarningIconSmall } from '../icons';
import { ChevronRight } from '../icons/chevronRight';
import { InfoIcon } from '../icons/InfoIcon';

export type FeedbackType = 'success' | 'error' | 'warning' | 'info';

export interface FeedbackInfo {
  type: FeedbackType;
  title: React.ReactNode;
  body?: React.ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
  icon?: React.ReactNode;
}

interface FeedbackBannerProps extends FeedbackInfo {}

export function FeedbackBanner({
  type,
  title,
  body,
  buttonText,
  onButtonClick,
  icon,
}: FeedbackBannerProps) {
  return (
    <Box
      sx={{
        padding: 3,
        display: 'flex',
        bgcolor: getBgColoroByType(type),
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: 2, md: 0 },
        borderRadius: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box pt="2px">
          {icon ?? (
            <>
              {(type === 'error' || type === 'info') && (
                <InfoIcon color="white" />
              )}
              {type === 'warning' && <WarningIconSmall color="white" />}
              {type === 'success' && <SuccessSmallIcon />}
            </>
          )}
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body2">{title}</Typography>

          {body && (
            <Typography fontSize="12px" color="#E5E5E5">
              {body}
            </Typography>
          )}
        </Box>
      </Box>
      {buttonText && (
        <Box>
          <Button
            variant="text"
            sx={{ color: 'white' }}
            endIcon={<ChevronRight size={16} />}
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        </Box>
      )}
    </Box>
  );

  function getBgColoroByType(type: FeedbackType) {
    return type === 'error'
      ? '#991B1B'
      : type === 'warning'
      ? '#7A1616'
      : type === 'success'
      ? '#182920'
      : type === 'info'
      ? '#383B3D'
      : 'black';
  }
}
