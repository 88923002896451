import { useSnackbar } from 'notistack';

import {
  Box,
  BoxProps,
  Button,
  Link,
  Typography,
  useTheme,
} from '@mui/material';

import {
  COINBASE_SMART_WALLET_LINK,
  SMART_WALLET_GUIDE,
} from '../../../../constants/externalLinks';
import { usePostHogCapture } from '../../../../hooks/usePostHogCapture';
import { useWalletData } from '../../../../hooks/useWalletData';
import { useWethBalance } from '../../../../hooks/useWethBalance';
import { truncAddress } from '../../../../lib/truncHash';
import { RefreshButton } from '../../../icons';
import UserTypeCard from '../../Cards/UserTypeCard';
import { FeedbackBanner } from '../../FeedbackBanner';

export function TopUpWalletContent({
  proceed,
  ...boxProps
}: { proceed?: () => void } & BoxProps) {
  const theme = useTheme();
  const { captureTopUpWallet } = usePostHogCapture();

  const { address, formatBalance, hasEnoughBalance, refetchBalance } =
    useWalletData();
  const {
    formatBalance: formatWethBalance,
    refetchBalance: refetchWethBalace,
  } = useWethBalance();

  const refetchBalances = () => {
    refetchBalance();
    refetchWethBalace();
  };

  const { enqueueSnackbar } = useSnackbar();

  async function copyToClipoard() {
    await navigator.clipboard.writeText(address ?? '');
    enqueueSnackbar(`Copied ${address} to your clipboard.`, {
      variant: 'success',
    });
  }

  function openCoinbaseWallet() {
    captureTopUpWallet();
    window.open(COINBASE_SMART_WALLET_LINK, '_blank', 'noopener,noreferrer');
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} {...boxProps}>
        <FeedbackBanner
          type="success"
          title="Only send ETH on Base Chain!"
          body={
            <Link
              href={SMART_WALLET_GUIDE}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              color="text.primary"
              fontSize={14}
              fontWeight={400}
            >
              Watch a 2-minute video
            </Link>
          }
        />

        <UserTypeCard
          title="Deposit from Coinbase"
          description="Use any device to deposit from Coinbase"
          action={openCoinbaseWallet}
          buttonText="Deposit"
        />

        <UserTypeCard
          title={
            <>
              Send ETH to your <br /> address on Base Chain
            </>
          }
          //  TODO: Replace with actual address
          description={truncAddress(address) ?? 'N/A'}
          action={copyToClipoard}
          buttonText="Copy address"
          disabled={!address}
        />
      </Box>

      {!hasEnoughBalance && (
        <Box pt={6}>
          <FeedbackBanner
            type="error"
            title="You don't have enough ETH in your wallet"
            body="Get ETH to cover gas for setting up your pricing as a creator and to spend on memberships to join communities as a follower."
          />
        </Box>
      )}
      <Box
        pt={6}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: 5, md: 0 }}
        pb={{ xs: 10, md: 0 }}
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column">
          <Typography color="#ACAFB3" variant="body1" mb={1}>
            Available balance on Base Chain
          </Typography>

          <Typography variant="body1">{formatBalance()} ETH</Typography>
          <Typography variant="body1" mt="2px">
            {formatWethBalance()} wETH
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
              cursor: 'pointer',
              mt: 3,
            }}
            onClick={refetchBalances}
          >
            <Typography
              color={theme.palette.primary.main}
              pb="3px"
              variant="body2"
            >
              Refresh
            </Typography>
            <RefreshButton />
          </Box>
        </Box>

        <Button
          size="large"
          onClick={() => proceed?.()}
          disabled={!hasEnoughBalance}
          sx={{ width: { xs: '100%', md: 'auto' } }}
        >
          Done
        </Button>
      </Box>
    </>
  );
}
