import {
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';

import { HOME_PATH } from '../constants/routings';
import { usePageContext } from '../renderer/usePageContext';

export default function MenuItem({
  href,
  text,
  Icon,
  onClick,
}: {
  href: string;
  text: string;
  Icon: any;
  onClick?: any;
}) {
  const pageContext = usePageContext();
  const isActive =
    pageContext.urlPathname === href ||
    pageContext.urlPathname.startsWith(href + HOME_PATH);

  const theme = useTheme();

  const textColor = !isActive
    ? theme.palette.text.primary
    : theme.palette.text.active;

  const background = !isActive
    ? 'transparent'
    : theme.palette.background.active;

  return (
    <ListItem key={href} disablePadding>
      <ListItemButton
        component={Link}
        href={href}
        key={text}
        sx={{
          py: 4,
          minHeight: '56px',
          color: textColor,
          background,
          borderRadius: 2,
          display: 'flex',
          gap: 3,
        }}
        onClick={onClick}
      >
        <ListItemIcon sx={{ stroke: textColor, minWidth: '24px' }}>
          {Icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
}
